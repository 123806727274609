import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ArrowCard,
  EarningComponent,
  Loader,
  ScrollContainer,
} from "../components";
import { Container, HalfContainer, FlexContainer } from "./style";
import filesize from "filesize";
import { getDeviceStatistics, getFormDetails, getDeviceStatisticsChartPoint, getLatestDevice } from "../methods/actions";
import { numberWithCommaswithoutdecimals } from "../methods/helper";

export const DeviceStatistics = () => {
  const dispatch = useDispatch();
  const devicestatistics = useSelector(
    (state) => state.DeviceReducer.devicestatistics
  );
  const deviceDetails = useSelector((state)=> state.DeviceReducer.deviceDetails);
  const latestDevice = useSelector((state)=> state.DeviceReducer.latestDevice);
  const devicegraphlabels = useSelector(
    (state) => state.DeviceReducer.devicegraphlabels
  );
  const devicegraphvalues = useSelector(
    (state) => state.DeviceReducer.devicegraphvalues
  );
  const gettingdevicestatistics = useSelector(
    (state) => state.LoaderReducer.gettingdevicestatistics
  );

  const gettingLatestDevices = useSelector((state)=> state.LoaderReducer.gettingLatestDevices)

  useEffect(() => {
    dispatch(getDeviceStatistics());
    dispatch(getLatestDevice())
    dispatch(getDeviceStatisticsChartPoint("", ""))
  }, [dispatch]);

  return (
    <ScrollContainer height={"600px"} margin={"160px"}>
      <Container>
        <EarningComponent
          filterElements={[]}
          chartLabels={devicegraphlabels}
          chartData={devicegraphvalues}
          title={"Devices"}
          label={"Devices"}
          link={""}
          percentage={
            gettingdevicestatistics ? (
              <Loader color={"#E5B910"} />
            ) : deviceDetails["deviceWeeklyGrowthPercentage"] ? (
              deviceDetails["deviceWeeklyGrowthPercentage"]
            ) : (
              ""
            )
          }
          percentsubvalue={"this week"}
          amount={
            gettingdevicestatistics ? (
              <Loader color={"#E5B910"} />
            ) : (
              numberWithCommaswithoutdecimals(devicestatistics["totalDevice"])
            )
          }
          description={"This is the total amount of devices on the network."}
          handlemouse={(e) =>
            dispatch(
              getFormDetails({
                props: ["description"],
                value: "This is the total amount of devices on the network.",
              })
            )
          }
          handlemouseleave={(e) =>
            dispatch(
              getFormDetails({
                props: ["description"],
                value: "",
              })
            )
          }
          pos={1}
        />
      </Container>
      <FlexContainer>
        <HalfContainer>
          <ArrowCard
            label="Total Data Volume"
            value={
              gettingdevicestatistics ? (
                <Loader color={"#E5B910"} />
              ) : devicestatistics["dataVolume"] ? (
                filesize(devicestatistics["dataVolume"])
              ) : (
                ""
              )
            }
            subvalue={
              ""
              // gettingdevicestatistics ? (
              //   <Loader color={"#E5B910"} />
              // ) : devicestatistics["onlinePercentageChange"] ? (
              //   devicestatistics["onlinePercentageChange"]
              // ) : (
              //   ""
              // )
            }
            naturalnumber={true}
          />
        </HalfContainer>
        <HalfContainer>
          <ArrowCard
            label={"Total Connections"}
            value={
              gettingdevicestatistics ? (
                <Loader color={"#E5B910"} />
              ) : (
                numberWithCommaswithoutdecimals(
                  devicestatistics.totalConnections
                )
              )
            }
            subvalue={
              ""
              // gettingdevicestatistics ? (
              //   <Loader color={"#E5B910"} />
              // ) : (
              //   devicestatistics["offlinePercentageChange"]
              // )
            }
            naturalnumber={true}
          />
        </HalfContainer>
      </FlexContainer>
      <FlexContainer>
        <HalfContainer>
          <ArrowCard
            label="Countries"
            value={
              gettingdevicestatistics ? (
                <Loader color={"#E5B910"} />
              ) : (
                devicestatistics["numberOfCountries"]
              )
            }
            subvalue={
              gettingdevicestatistics ? (
                <Loader color={"#E5B910"} />
              ) : (
                devicestatistics["countryChangeRate"]
              )
            }
            handleNext={() => console.log("Next")}
            naturalnumber={true}
          />
        </HalfContainer>
        <HalfContainer>
          <ArrowCard
            label={"Cities"}
            value={
              gettingdevicestatistics ? (
                <Loader color={"#E5B910"} />
              ) : (
                devicestatistics["numberOfCities"]
              )
            }
            subvalue={
              gettingdevicestatistics ? (
                <Loader color={"#E5B910"} />
              ) : (
                devicestatistics["cityChangeRate"]
              )
            }
            handlechange={() =>
              dispatch(
                getFormDetails({
                  props: ["activetab"],
                  value: 4,
                })
              )
            }
            naturalnumber={true}
          />
        </HalfContainer>
      </FlexContainer>
      <Container>
        <ArrowCard
          label="Latest Device"
          value={
            gettingLatestDevices ? (
              <Loader color={"#E5B910"} />
            ) : latestDevice ? (
              latestDevice.name
            ) : (
              ""
            )
          }
          subvalue={
            gettingLatestDevices ? (
              <Loader color={"#E5B910"} />
            ) : latestDevice ? (
              <span style={{ fontSize: "0.6rem" }}>
                <img
                  height={"10px"}
                  className="img-responsive mr-5"
                  src={latestDevice.flagIconSmall}
                  alt=""
                />
                {latestDevice.city
                  ? latestDevice.city +
                    ", " +
                    latestDevice.country
                  : "--"}
              </span>
            ) : (
              ""
            )
          }
          percentage={""}
          color={"grey2"}
          percentsubvalue={""}
          handlechange={() =>
            dispatch(
              getFormDetails({
                props: ["activetab"],
                value: 3,
              })
            )
          }
        />
      </Container>
    </ScrollContainer>
  );
};
