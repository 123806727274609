import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ArrowCard,
  EarningComponent,
  Loader,
  RewardCard,
} from "../../components";
import { Container, HalfContainer, FlexContainer } from "./style";
import {
  getBlockHeightStatistics,
  getOutlierStatistics,
  getDistributedRewards,
  getFormDetails,
  getStakedStatistics,
  getDeviceStatisticsChartPoint
} from "../../methods/actions";
import {
  numberWithCommaswithoutdecimals,
  precise_round,
} from "../../methods/helper";
import { ScrollContainer } from "../../components";

export const WicryptExplorer = () => {
  const dispatch = useDispatch();
  const [currfilter, setCurrfilter] = useState("ALL");
  const deviceDetails = useSelector(
    (state) => state.DeviceReducer.deviceDetails
  );
  const devicegraphlabels = useSelector(
    (state) => state.DeviceReducer.devicegraphlabels
  );
  const devicegraphvalues = useSelector(
    (state) => state.DeviceReducer.devicegraphvalues
  );
  const gettingStatisticsChartpoint = useSelector(
    (state) => state.LoaderReducer.gettingStatisticsChartpoint
  );
  const gettingOutlierStatistics = useSelector(
    (state) => state.LoaderReducer.gettingOutlierStatistics
  );

  const blockheightstatistics = useSelector(
    (state) => state.BlockHeightReducer.blockheightstatistics
  );
  const outlierStatistics = useSelector(
    (state) => state.OutlierReducer.outlierStatistics
  );

  const gettingRewards = useSelector(
    (state) => state.LoaderReducer.gettingRewards
  );
  const totalrewards = useSelector((state) => state.RewardReducer.totalrewards);
  const totalrewardsinwnt = useSelector(
    (state) => state.RewardReducer.totalrewardsinwnt
  );
  const rewarddates = useSelector((state) => state.RewardReducer.rewarddates);
  const rewardamts = useSelector((state) => state.RewardReducer.rewardamts);
  const stats = useSelector((state) => state.MarketReducer.marketStatistics);
  const gettingMarketStatistics = useSelector(
    (state) => state.LoaderReducer.gettingMarketStatistics
  );
  const gettingBlockHeightStatistics = useSelector(
    (state) => state.LoaderReducer.gettingBlockHeightStatistics
  );
  const stakedstats = useSelector((state) => state.RewardReducer.stakedstats);
  const gettingStakedStatistics = useSelector(
    (state) => state.LoaderReducer.gettingStakedStatistics
  );

  const handlefilter = (val) => {
    setCurrfilter(val);
    if (val === "ALL") {
      dispatch(getDistributedRewards("", "", "", "", ""));
    } else if (val === "24H") {
      dispatch(getDistributedRewards("", 24, "", "", ""));
    } else if (val === "30D") {
      dispatch(getDistributedRewards("", "", 30, "", ""));
    } else if (val === "6M") {
      dispatch(getDistributedRewards("", "", "", 6, ""));
    } else {
      dispatch(getDistributedRewards("", "", "", "", 1));
    }
  };

  useEffect(() => {
    dispatch(getDeviceStatisticsChartPoint("", ""))
    dispatch(getBlockHeightStatistics());
    dispatch(getOutlierStatistics(24, 30));
    dispatch(getDistributedRewards("", "", "", "", ""));
    // dispatch(getMarketStatistics());
    dispatch(getStakedStatistics());
    //FInd a better way to handle this
    dispatch(
      getFormDetails({
        props: ["currtabs"],
        value: [],
      })
    );
    dispatch(
      getFormDetails({
        props: ["oldroutes"],
        value: [],
      })
    );
    dispatch(
      getFormDetails({
        props: ["newroutes"],
        value: [],
      })
    );
    dispatch(
      getFormDetails({
        props: ["hasdets"],
        value: 0,
      })
    );
    //This block of code
  }, [dispatch]);

  return (
    <ScrollContainer height={"500px"} margin={"230px"}>
      <Container>
        <EarningComponent
          filterElements={[]}
          chartLabels={devicegraphlabels}
          chartData={devicegraphvalues}
          label={"Devices"}
          title={"Devices"}
          link={"/devices"}
          description={"This is the total amount of devices on the network."}
          handlemouse={() =>
            dispatch(
              getFormDetails({
                props: ["description"],
                value: "This is the total amount of devices on the network.",
              })
            )
          }
          handlemouseleave={() =>
            dispatch(
              getFormDetails({
                props: ["description"],
                value: "",
              })
            )
          }
          pos={1}
          percentage={
            gettingOutlierStatistics ? (
              <Loader color={"#E5B910"} />
            ) : deviceDetails ? (
              deviceDetails.deviceWeeklyGrowthPercentage
            ) : (
              ""
            )
          }
          percentsubvalue={"this week"}
          amount={
            gettingStatisticsChartpoint ? (
              <Loader color={"#E5B910"} />
            ) : (
              numberWithCommaswithoutdecimals(deviceDetails.totalDevice || 0)
            )
          }
        />
      </Container>
      <Container>
        <RewardCard
          filterElements={["ALL", "24H", "30D", "6M", "1Y"]}
          currfilter={currfilter}
          triggerfilter={handlefilter}
          chartLabels={rewarddates}
          chartData={rewardamts}
          label={"Distributed Rewards"}
          title={"Distributed Rewards"}
          link={"/leaderboard"}
          percentage={""}
          percentsubvalue={"this week"}
          description={"This is the value of the total rewards distributed"}
          handlemouse={(e) =>
            dispatch(
              getFormDetails({
                props: ["description"],
                value: "This is the value of the total rewards distributed",
              })
            )
          }
          handlemouseleave={(e) =>
            dispatch(
              getFormDetails({
                props: ["description"],
                value: "",
              })
            )
          }
          pos={0}
          amount={
            gettingRewards ? (
              <Loader color={"#E5B910"} />
            ) : (
              numberWithCommaswithoutdecimals(
                precise_round(totalrewardsinwnt, 2)
              ) + "WNT"
            )
          }
          subvalue={
            gettingRewards ? (
              <Loader color={"#E5B910"} />
            ) : (
              "$" +
              numberWithCommaswithoutdecimals(precise_round(totalrewards, 2))
            )
          }
        />
      </Container>
      <FlexContainer>
        <HalfContainer>
          <ArrowCard
            label={"Blockheight"}
            description={
              "This is the current size of the blocks in the blockchain"
            }
            handlemouse={(e) =>
              dispatch(
                getFormDetails({
                  props: ["description"],
                  value:
                    "This is the current size of the blocks in the blockchain",
                })
              )
            }
            handlemouseleave={(e) =>
              dispatch(
                getFormDetails({
                  props: ["description"],
                  value: "",
                })
              )
            }
            value={
              gettingBlockHeightStatistics ? (
                <Loader color={"#E5B910"} />
              ) : (
                numberWithCommaswithoutdecimals(
                  blockheightstatistics.block?.value
                )
              )
            }
            subvalue={
              gettingBlockHeightStatistics ? (
                <Loader color={"#E5B910"} />
              ) : (
                blockheightstatistics.block?.changeCount
              )
            }
            link={"/block_height"}
          />
        </HalfContainer>
        <HalfContainer>
          <ArrowCard
            label={"Market"}
            pos={3}
            description={"This is the current value of WNT"}
            handlemouse={() =>
              dispatch(
                getFormDetails({
                  props: ["description"],
                  value: "This is the current value of WNT",
                })
              )
            }
            handlemouseleave={() =>
              dispatch(
                getFormDetails({
                  props: ["description"],
                  value: "",
                })
              )
            }
            value={
              gettingMarketStatistics ? (
                <Loader color={"#E5B910"} />
              ) : (
                precise_round(stats.marketPrice, 4)
              )
            }
            subvalue={
              gettingMarketStatistics ? (
                <Loader color={"#E5B910"} />
              ) : (
                precise_round(stats.priceChangePercentage24h, 4)
              )
            }
            link={"/market"}
          />
        </HalfContainer>
      </FlexContainer>
      <FlexContainer>
        <HalfContainer>
          <ArrowCard
            label={"Outliers"}
            description={
              "This is the total number of Wicrypt users who have minted NFT devices"
            }
            handlemouse={() =>
              dispatch(
                getFormDetails({
                  props: ["description"],
                  value:
                    "This is the total number of Wicrypt users who have minted NFT devices",
                })
              )
            }
            handlemouseleave={() =>
              dispatch(
                getFormDetails({
                  props: ["description"],
                  value: "",
                })
              )
            }
            value={
              gettingOutlierStatistics ? (
                <Loader color={"#E5B910"} />
              ) : (
                numberWithCommaswithoutdecimals(
                  outlierStatistics.deviceHoldersCount
                )
              )
            }
            subvalue={
              gettingOutlierStatistics ? (
                <Loader color={"#E5B910"} />
              ) : (
                outlierStatistics.outlierPercentageChange
              )
            }
            link={"/outliers"}
          />
        </HalfContainer>
        <HalfContainer>
          <ArrowCard
            pos={3}
            label={"WNT Staked"}
            description={
              "This is the total amount of WNT staked on the Wicrypt platform."
            }
            handlemouse={(e) =>
              dispatch(
                getFormDetails({
                  props: ["description"],
                  value:
                    "This is the total amount of WNT staked on the Wicrypt platform.",
                })
              )
            }
            handlemouseleave={(e) =>
              dispatch(
                getFormDetails({
                  props: ["description"],
                  value: "",
                })
              )
            }
            value={
              gettingStakedStatistics ? (
                <Loader color={"#E5B910"} />
              ) : (
                numberWithCommaswithoutdecimals(precise_round(stakedstats.totalStakedWNT, 2))
              )
            }
            subvalue={
              gettingStakedStatistics ? (
                <Loader color={"#E5B910"} />
              ) : (
                precise_round(stakedstats.totalStakedWNT * stats.marketPrice, 2)
              )
            }
            link={"/staked"}
          />
        </HalfContainer>
      </FlexContainer>
      <FlexContainer>
        <HalfContainer>
          <ArrowCard
            label={"Users"}
            description={
              "This is the total number of Wicrypt users"
            }
            handlemouse={() =>
              dispatch(
                getFormDetails({
                  props: ["description"],
                  value:
                    "This is the total number of Wicrypt users",
                })
              )
            }
            handlemouseleave={() =>
              dispatch(
                getFormDetails({
                  props: ["description"],
                  value: "",
                })
              )
            }
            value={
              gettingOutlierStatistics ? (
                <Loader color={"#E5B910"} />
              ) : (
                numberWithCommaswithoutdecimals(
                  outlierStatistics.numberOfUsers
                )
              )
            }
          />
        </HalfContainer>
      </FlexContainer>
    </ScrollContainer>
  );
};
