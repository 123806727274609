const _const = {
  //General
  FILL_OUT_FORM: "FILL_OUT_FORM",
  COPIED_TO_CLIPBOARD: "COPIED_TO_CLIPBOARD",
  TOGGLE_MODAL: "TOGGLE_MODAL",
  SEARCHING_EXPLORER: "SEARCHING_EXPLORER",
  SEARCH_RESULTS: "SEARCH_RESULTS",

  //Devices
  GETTING_DEVICE_STATISTICS: "GETTING_DEVICE_STATISTICS",
  ALL_DEVICE_STATISTICS: "ALL_DEVICE_STATISTICS",
  GETTING_MY_DEVICES: "GETTING_MY_DEVICES",
  ALL_MY_DEVICES: "ALL_MY_DEVICES",
  GETTING_LATEST_DEVICES: "GETTING_LATEST_DEVICES",
  ALL_LATEST_DEVICES: "ALL_LATEST_DEVICES",
  GETTING_DEVICE_CITIES: "GETTING_DEVICE_CITIES",
  ALL_DEVICE_CITIES: "ALL_DEVICE_CITIES",
  STARTING_SIMULATION: "STARTING_SIMULATION",
  DEVICE_CONNECTED: "DEVICE_CONNECTED",
  SIMULATION_FAILED: "SIMULATION_FAILED",
  GETTING_CITY_STATISTICS: "GETTING_CITY_STATISTICS",
  CITY_DEVICE_STATISTICS: "CITY_DEVICE_STATISTICS",
  GETTING_DEVICES_IN_CITY: "GETTING_DEVICES_IN_CITY",
  ALL_DEVICES_IN_CITY: "ALL_DEVICES_IN_CITY",
  GETTING_ONE_DEVICE_STATISTICS: "GETTING_ONE_DEVICE_STATISTICS",
  ONE_DEVICE_STATISTICS: "ONE_DEVICE_STATISTICS",
  CLEAR_OLD_STATISTICS: "CLEAR_OLD_STATISTICS",
  GETTING_ALL_DEVICES: "GETTING_ALL_DEVICES",
  ALL_DEVICES: "ALL_DEVICES",
  GETTING_DEVICE_TRANSACTIONS: "GETTING_DEVICE_TRANSACTIONS",
  DEVICE_TRANSACTIONS: " DEVICE_TRANSACTIONS",
  ALL_MY_RESULT_DEVICES: "ALL_MY_RESULT_DEVICES",
  GETTING_DEVICE_STATISTICS_CHARTPOINT: "GETTING_DEVICE_STATISTICS_CHARTPOINT",
  ALL_DEVICE_STATISTICS_CHARTPOINT: "ALL_DEVICE_STATISTICS_CHARTPOINT",
  LATEST_DEVICE: "LATEST_DEVICE",

  //Leaderboard
  GETTING_DEVICE_LEADERBOARD: "GETTING_DEVICE_LEADERBOARD",
  DEVICE_LEADERBOARD: "DEVICE_LEADERBOARD",
  UPDATE_LEADERBOARD: "UPDATE_LEADERBOARD",
  GETTING_CURRENT_WEEK: "GETTING_CURRENT_WEEK",
  CURRENT_WEEK: "CURRENT_WEEK",

  //Cities
  DEVICES_BY_VOLUME: "DEVICES_BY_VOLUME",
  DEVICES_BY_CONNECTIONS: "DEVICES_BY_CONNECTIONS",

  //Blockheight
  GETTING_BLOCKHEIGHT_STATISTICS: "GETTING_BLOCKHEIGHT_STATISTICS",
  BLOCKHEIGHT_STATISTICS: "BLOCKHEIGHT_STATISTICS",
  LATEST_BLOCKS: "LATEST_BLOCKS",
  GETTING_LATEST_BLOCKS: "GETTING_LATEST_BLOCKS",
  BLOCK_DETAIL: "BLOCK_DETAIL",
  GETTING_BLOCK_DETAIL: "GETTING_BLOCK_DETAIL",
  GETTING_TRANSACTION_DETAIL: "GETTING_TRANSACTION_DETAIL",
  BLOCK_TRANSACTION_DETAIL: "BLOCK_TRANSACTION_DETAIL",
  GETTING_BLOCK_HASH: "GETTING_BLOCK_HASH",
  BLOCK_HASH: "BLOCK_HASH",
  GETTING_BLOCKHEIGHT_CHARTPOINT: "GETTING_BLOCKHEIGHT_CHARTPOINT",
  BLOCKHEIGHT_CHARTPOINT: "BLOCKHEIGHT_CHARTPOINT",
  GETTING_LATEST_DEVICES: "GETTING_LATEST_DEVICES",

  //Outliers
  GETTING_ALL_OUTLIERS: "GETTING_ALL_OUTLIERS",
  ALL_OUTLIERS: "ALL_OUTLIERS",
  SEARCH_ALL_OUTLIERS: "SEARCH_ALL_OUTLIERS",
  OUTLIER_DETAILS: "OUTLIER_DETAILS",
  GETTING_OUTLIER_DETAIL: "GETTING_OUTLIER_DETAIL",
  GETTING_OUTLIER_DEVICES: "GETTING_OUTLIER_DEVICES",
  OUTLIER_DEVICES: "OUTLIER_DEVICES",
  GETTING_OUTLIER_STATISTICS: "GETTING_OUTLIER_STATISTICS",
  OUTLIER_STATISTICS: "OUTLIER_STATISTICS",
  CLEAR_ALL_OUTLIERS: "CLEAR_ALL_OUTLIERS",

  //Market
  GETTING_MARKET_STATISTICS: "GETTING_MARKET_STATISTICS",
  MARKET_STATISTICS: "MARKET_STATISTICS",

  //Rewards
  GETTING_DISTRIBUTED_REWARDS: "GETTING_DISTRIBUTED_REWARDS",
  DISTRIBUTED_REWARDS: "DISTRIBUTED_REWARDS",
  GETTING_STAKED_STATISTICS: "GETTING_STAKED_STATISTICS",
  STAKED_STATISTICS: "STAKED_STATISTICS",
  GETTING_CLAIMING_HISTORY: "GETTING_CLAIMING_HISTORY",
  CLAIM_HISTORY: "CLAIM_HISTORY",

  //Map
  GETTING_ISP: "GETTING_ISP",
  ALL_ISP: "ALL_ISP",
};

export default _const;
