import _const from "../../constants/types";
import axios from "axios";
import config from "../config";

//Gets Blockheight Statistics
export const getBlockHeightStatistics = () => {
  return dispatch => {
    dispatch({
      type: _const.GETTING_BLOCKHEIGHT_STATISTICS,
      payload: true
    });
    axios
      .get(config.apiUrl + `/Block/Statistics`)
      .then(res => {
        dispatch({
          type: _const.GETTING_BLOCKHEIGHT_STATISTICS,
          payload: false
        });
        dispatch({
          type: _const.BLOCKHEIGHT_STATISTICS,
          payload: res.data.data
        });
      })
      .catch(err => {
        dispatch({
          type: _const.GETTING_BLOCKHEIGHT_STATISTICS,
          payload: false
        });
      });
  };
};

//Block height chat points
export const getBlockHeightChatPoints = (period) => {
  return dispatch => {
    dispatch({
      type: _const.GETTING_BLOCKHEIGHT_CHARTPOINT,
      payload: true
    });
    axios
      .get(config.apiUrl + `/Block/ChartPoints?chartLookBackPeriodInDays=${period}`)
      .then(res => {
        dispatch({
          type: _const.GETTING_BLOCKHEIGHT_CHARTPOINT,
          payload: false
        });
        dispatch({
          type: _const.BLOCKHEIGHT_CHARTPOINT,
          payload: res.data.data
        });
      })
      .catch(err => {
        dispatch({
          type: _const.GETTING_BLOCKHEIGHT_CHARTPOINT,
          payload: false
        });
      });
  };
};

//Gets Blockheight Latest Blocks
export const getLatestBlocks = limit => {
  return dispatch => {
    dispatch({
      type: _const.GETTING_LATEST_BLOCKS,
      payload: true
    });
    axios
      .get(config.apiUrl + `/Block/Latest?limit=${limit}`)
      .then(res => {
        dispatch({
          type: _const.GETTING_LATEST_BLOCKS,
          payload: false
        });
        dispatch({
          type: _const.LATEST_BLOCKS,
          payload: res.data.data
        });
      })
      .catch(err => {
        dispatch({
          type: _const.GETTING_LATEST_BLOCKS,
          payload: false
        });
      });
  };
};

//Gets detail for a block
export const getBlockDetail = blockNumber => {
  return dispatch => {
    dispatch({
      type: _const.GETTING_BLOCK_DETAIL,
      payload: true
    });
    axios
      .get(config.apiUrl + `/Block/Detail?blockNumber=${blockNumber}`)
      .then(res => {
        dispatch({
          type: _const.GETTING_BLOCK_DETAIL,
          payload: false
        });
        dispatch({
          type: _const.BLOCK_DETAIL,
          payload: res.data.data
        });
      })
      .catch(err => {
        dispatch({
          type: _const.GETTING_BLOCK_DETAIL,
          payload: false
        });
      });
  };
};

//Gets detail for a transaction
export const getBlockTransactionDetail = transactionId => {
  return dispatch => {
    dispatch({
      type: _const.GETTING_TRANSACTION_DETAIL,
      payload: true
    });
    axios
      .get(
        config.apiUrl +
          `/Block/TransactionMetadata?transactionId=${transactionId}`
      )
      .then(res => {
        dispatch({
          type: _const.GETTING_TRANSACTION_DETAIL,
          payload: false
        });
        dispatch({
          type: _const.BLOCK_TRANSACTION_DETAIL,
          payload: res.data.data
        });
      })
      .catch(err => {
        dispatch({
          type: _const.GETTING_TRANSACTION_DETAIL,
          payload: false
        });
      });
  };
};

//Gets detail for a block hash
export const getBlockHashDetail = blockHash => {
  return dispatch => {
    dispatch({
      type: _const.GETTING_BLOCK_HASH,
      payload: true
    });
    axios
      .get(config.apiUrl + `/Block/Detail/Hash?blockHash=${blockHash}`)
      .then(res => {
        dispatch({
          type: _const.GETTING_BLOCK_HASH,
          payload: false
        });
        dispatch({
          type: _const.BLOCK_HASH,
          payload: res.data.data
        });
      })
      .catch(err => {
        dispatch({
          type: _const.GETTING_BLOCK_HASH,
          payload: false
        });
      });
  };
};
