import _const from "../../constants/types";
import moment from "moment";

const initialState = {
  blockheightstatistics: {},
  statisticslabels: [],
  statisticsvalues: [],
  latestblocks: [],
  selectedblock: {},
  blockDetail: {},
  blocktransactions: [],
  blockTransactionDetail: {},
  blockHashDetail: {},
  blockhashtransactions: [],
};

const BlockheightReducer = (state = initialState, action) => {
  switch (action.type) {
    case _const.BLOCKHEIGHT_STATISTICS:

      return {
        ...state,
        blockheightstatistics: action.payload,
      };

    case _const.BLOCKHEIGHT_CHARTPOINT:
      const dates = [];
      const pointvalues = [];

      if (action.payload.length !== 0) {
        const data = action.payload;

        data.forEach((each) => {
          dates.push(moment(each.date).format("MMMM Do YYYY"));
          pointvalues.push(each.numberOfBlocks);
        });
      }

      return {
        ...state,
        statisticslabels: dates,
        statisticsvalues: pointvalues,
      };

    case _const.LATEST_BLOCKS:
      return {
        ...state,
        latestblocks: action.payload,
        selectedblock: action.payload[0],
      };

    case _const.BLOCK_DETAIL:
      return {
        ...state,
        blockDetail: action.payload,
        blocktransactions: action.payload.transactions,
      };

    case _const.BLOCK_TRANSACTION_DETAIL:
      return { ...state, blockTransactionDetail: action.payload };

    case _const.BLOCK_HASH:
      return {
        ...state,
        blockHashDetail: action.payload,
        blockhashtransactions: action.payload.transactions,
      };

    default:
      return state;
  }
};

export default BlockheightReducer;
