import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EarningComponent, ArrowCard, Loader } from "../../../../components";
import { Container, CardCont, FlexCardContainer, FlexCard } from "./style";
import {
  getBlockHeightStatistics,
  getBlockHeightChatPoints,
  getLatestBlocks,
  getFormDetails,
} from "../../../../methods/actions";
import { numberWithCommaswithoutdecimals } from "../../../../methods/helper";

export const Statistics = () => {
  const dispatch = useDispatch();

  const blockheightstatistics = useSelector(
    (state) => state.BlockHeightReducer.blockheightstatistics
  );
  const statisticslabels = useSelector(
    (state) => state.BlockHeightReducer.statisticslabels
  );
  const statisticsvalues = useSelector(
    (state) => state.BlockHeightReducer.statisticsvalues
  );
  const selectedblock = useSelector(
    (state) => state.BlockHeightReducer.selectedblock
  );

  const gettingBlockHeightStatistics = useSelector(
    (state) => state.LoaderReducer.gettingBlockHeightStatistics
  );

  useEffect(() => {
    dispatch(getBlockHeightStatistics());
    dispatch(getBlockHeightChatPoints(""));
    dispatch(getLatestBlocks(10));
  }, [dispatch]);

  return (
    <Container>
      <CardCont>
        <EarningComponent
          label={"rate"}
          title={"Transaction Rate"}
          percentage={
            gettingBlockHeightStatistics ? (
              <Loader color={"#E5B910"} />
            ) : (
              `${
                blockheightstatistics.transaction
                  ? blockheightstatistics.transaction.percentageChange
                  : ""
              }`
            )
          }
          percentsubvalue={"Last 100 blocks"}
          amount={
            gettingBlockHeightStatistics ? (
              <Loader color={"#E5B910"} />
            ) : blockheightstatistics.transaction ? (
              numberWithCommaswithoutdecimals(
                blockheightstatistics.transaction.value
              )
            ) : (
              ""
            )
          }
          description={
            "This is the total amount of transactions performed on the blockchain."
          }
          handlemouse={(e) =>
            dispatch(
              getFormDetails({
                props: ["description"],
                value:
                  "This is the total amount of transactions performed on the blockchain.",
              })
            )
          }
          handlemouseleave={(e) =>
            dispatch(
              getFormDetails({
                props: ["description"],
                value: "",
              })
            )
          }
          pos={1}
          chartLabels={statisticslabels}
          chartData={statisticsvalues}
          filterElements={[]}
        />
      </CardCont>
      <FlexCardContainer>
        <FlexCard>
          <ArrowCard
            pos={0}
            description={"This is the total number of blocks on the blockchain"}
            label={"  Blocks"}
            handlemouse={(e) =>
              dispatch(
                getFormDetails({
                  props: ["description"],
                  value: "This is the total number of blocks on the blockchain",
                })
              )
            }
            handlemouseleave={(e) =>
              dispatch(
                getFormDetails({
                  props: ["description"],
                  value: "",
                })
              )
            }
            value={
              gettingBlockHeightStatistics ? (
                <Loader color={"#E5B910"} />
              ) : blockheightstatistics.block ? (
                numberWithCommaswithoutdecimals(
                  blockheightstatistics.block.value
                )
              ) : (
                ""
              )
            }
            subvalue={
              gettingBlockHeightStatistics ? (
                <Loader color={"#E5B910"} />
              ) : (
                blockheightstatistics.block?.changeCount
              )
            }
            link={`/block/stats/${selectedblock.blockNumber}`}
          />
        </FlexCard>
        <FlexCard>
          <ArrowCard
            pos={2}
            label={"Block time (24hrs)"}
            description={
              "This is the average time it takes to generate a new block"
            }
            handlemouse={(e) =>
              dispatch(
                getFormDetails({
                  props: ["description"],
                  value:
                    "This is the average time it takes to generate a new block",
                })
              )
            }
            handlemouseleave={(e) =>
              dispatch(
                getFormDetails({
                  props: ["description"],
                  value: "",
                })
              )
            }
            value={
              gettingBlockHeightStatistics ? (
                <Loader color={"#E5B910"} />
              ) : blockheightstatistics.averageBlockTime ? (
                numberWithCommaswithoutdecimals(
                  blockheightstatistics.averageBlockTime.value
                ) + " Secs"
              ) : (
                ""
              )
            }
            subvalue={
              gettingBlockHeightStatistics ? (
                <Loader color={"#E5B910"} />
              ) : blockheightstatistics.averageBlockTime ? (
                numberWithCommaswithoutdecimals(
                  blockheightstatistics.averageBlockTime.changeCount
                ) + " Secs"
              ) : (
                ""
              )
            }
          />
        </FlexCard>
      </FlexCardContainer>
    </Container>
  );
};
